
/* navbar */

.bg-body-tertiary{
   position: fixed;
   width: 100%;
   top: 0;
   z-index: 9;
}
.nav-drop{
    font-size: 20px;
    color:rgb(12, 12, 108) ;
}
.m-auto{
    font-size: 20px;
}
.nav-links{
    color:rgb(12, 12, 108) ;
    padding: 10px;
    font-weight: 600;
}
#basic-nav-dropdown{
    color:rgb(12, 12, 108) ;
    padding: 10px;
    font-weight: 600;
}
.d-inline-block {
   
    width: 250px;
    height: 100px;
}

.icon{
    margin:5%;   
} 
.top-nav{
   overflow: hidden;
}

/* home */

.entry-image{
    position: relative;
    margin-top: 30%;
}
 .physio_img1{
   margin-top: 15%;
    width: 100%;
 }
 .caption{
        /* position: absolute; */
        background: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 22px;
        color:rgb(12, 12, 108) ;
        margin: 5%;
 }
 .caption1{
   width: fit-content;
 }
 .sub{
  margin: 13%;
  font-size: 28px;
  font-weight: 600;
  color:rgb(12, 12, 108);
  text-align: center;
 }
 .whatsp-icon{
   cursor: pointer;
   width: 50px;
   height: 50px;  
}
.whatsp-icon:hover {
   animation: shake 0.5s;
   animation-iteration-count: infinite;
 }
 @keyframes shake {
   0% { transform: translate(1px, 1px) rotate(0deg); }
   10% { transform: translate(-1px, -2px) rotate(-1deg); }
   20% { transform: translate(-3px, 0px) rotate(1deg); }
   30% { transform: translate(3px, 2px) rotate(0deg); }
   40% { transform: translate(1px, -1px) rotate(1deg); }
   50% { transform: translate(-1px, 2px) rotate(-1deg); }
   60% { transform: translate(-3px, 1px) rotate(0deg); }
   70% { transform: translate(3px, 1px) rotate(-1deg); }
   80% { transform: translate(-1px, -1px) rotate(1deg); }
   90% { transform: translate(1px, 2px) rotate(0deg); }
   100% { transform: translate(1px, -2px) rotate(-1deg); }
 }
.whatsp{
   z-index: 9;
   position: fixed;
   left: 80%;
   top: 75%;
   width: 50px;
   height: 50px;
}
.about{
  /* margin-left: 6%; */
  background-image: linear-gradient(rgb(249, 249, 253),rgb(238, 238, 250),white);
}
 .abt-images{
    width: 100%;
    margin-top: 1%;
 }
 .abt-images:hover{
   transform: scale(1.2);
   transition: 1s;
 }
 .sub-headings{
   padding: 8%;
   color:rgb(12, 12, 108) ;
   margin-top: 25%;
   font-weight: 800;
   font-size: 25px;
   font-family: 'Poppins', sans-serif;
 }
 .sub1{
   margin-top: 2%;
   font-size: 22px;
   margin-left: 10%;
   color: rgb(88, 88, 96);
 }
 .sub1:hover{
   transform: scale(1.1);
   margin-left: 10%;
   transition: 1s;
 }
 
 .para{
    color: black;
    margin: 8%;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    line-height: 35px;
 }

 /* services */

 .service-discr{
  background-image: linear-gradient(white,rgb(249, 249, 253),rgb(238, 238, 250),white);
 }
 .services{
   width: 100%;  
   padding: 2%;
 }
.service{
   background-image: linear-gradient(rgb(224, 234, 243),rgb(242, 240, 242));
   padding:3%;
   border-radius: 8px 8px;
   margin: 8%;
   border: .5px solid rgb(228, 233, 240);
}
.service:hover{
   transition: 1s;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.service-head{
   color: rgb(58, 58, 104);
   text-align: center;
   margin: 10px;
   font-size: 22px;
   font-weight:bold
}
.service-text{
   text-align: center;
   line-height: 25px;
   font-size: 17px;
}
.service-btn{
   margin: 50px auto;
   padding: 5px 50px;
   border-radius: 12px;
   border: none;
}
.service-btn:hover{
   background-color: rgb(86, 84, 84);
   color: white;
}

/* why choose us */

.reason{
  margin: 111px;
}
.reason1{
  display: flex;
}
.reason-name{
  cursor: pointer;
  margin: 15px;
  font-size: 20px;
}
.reason-icon{
  color: rgb(16, 16, 76);
  margin: 15px;
  font-size: 17px;
}


/* testimonials */
.testimonial-carousel {
   width: 300px;
   margin: auto;
   padding: 20px;
   border: 1px solid #ccc;
   border-radius: 8px;
   text-align: center;
   background-color: #f9f9f9;
 }
 
 .testimonial-card {
   margin-bottom: 20px;
 }
 
 button {
   padding: 10px;
   border: none;
   background-color: #4caf50;
   color: white;
   cursor: pointer;
   border-radius: 5px;
   margin: 5px;
 }
 
 button:hover {
   background-color: #45a049;
 }


 /* contact */
 .contact{
  background-image: linear-gradient(white,rgb(249, 249, 253),rgb(238, 238, 250),white);
 }
.contactContainer{
   margin: 5%;
}
 .contacting{
   display: flex;
   cursor: pointer;
 }
 .contactMethod{
   margin-left: 7%;
   font-size: 20px;
 }
 .contactIcon{
  margin-left: 5%;
   width: 25px;
   height: 50px;
 }
 .contactIcon:hover{
   transform: scale(1.3);
   transition: .5s;
 }

 /* footer */

 .foot{
    width: 100%;
    background-color: rgb(35, 35, 69);
    color: white;
 }
 .foot-discr{
    padding-bottom: 5%;   
    font-size: 15px;
    text-align: center;
 }
 .foot1{
   padding: 25px;
   font-size: 15px;
   text-align: center;
 }
 .foot2-head{
   padding: 30px;
   font-size: 21px;
   font-weight: 500;
 }
 .foot2 li{
   cursor: pointer;
   list-style-type: none;
   font-size: 20;
   line-height: 1.9;
 }
 .foot2 li a{
   color: white;
   text-decoration: none;
 }
 .icon-head{
   padding: 30px;
   font-size: 21px;
   font-weight: 500;
 }
 .icons ul{
   display: flex;
   margin-left: 15%;
 }
 .icons li{
    list-style-type: none;
    padding: 8px;
 }
 .icon1{
   cursor: pointer;
   width: 30px;
   height: 30px;
   margin-left: 20px;
 }
 .icon1:hover{
   transform: scale(1.2);
   transition: .5s;
 }
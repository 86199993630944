@media screen and (min-width: 600px){
   /* navbar */
   
    .d-inline-block{
        width: 250px;
        height: 100px;
     }
     .nav-links{
        font-size: 20px;
     }
     .nav-links:hover{
        transform: scale(1.05);
        transition: .5s;
     }
     #basic-nav-dropdown{
        font-size: 20px;
     }
     #basic-nav-dropdown:hover{
        transform: scale(1.05);
        transition: .5s;
     }
     /* home */
    .entry-image{
        margin-top: 3%;
        width: 90%;
    }
    .physio_img1{
        margin-top: 60px;
        width: 110%;
        opacity: 1;
    }
    .caption{
        /* position: absolute; */
        background: none;
        /* top: 3%; */
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-weight: 600;
        font-size: 45px;
        color:rgb(12, 12, 108) ;  
        
    }
    .whatsp-icon{    
        width: 50px;
        height: 50px;    
    }
    .whatsp{
        left: 85%;
        top: 85%;
        width: 50px;
        height: 50px;
    }
    .sub{
        font-size: 45px;
        margin: 2%;
    }
    .about{
        background-image: none;
    }
    .abt-images{
        margin-top: 10%;
        width:450px;
        height: 350px;
        margin-left: 1%;
    }
    .sub1{
        font-size: 30px;
        margin-left: 6%;
        padding: 35px;
    }
    .sub-headings{
        margin-top: 10%;
        margin-left: 7%;
        padding: 2%;
        font-size: 45px;
        font-weight: 600;
    }
    .para{
        /* margin: 3%; */
        line-height: 1.9;
        /* padding: 20px; */
        margin-left: 8%;
        text-align: justify;
        font-size: 22px;
    }
     .service{
        margin: auto;
        margin-top: 3%;
        width: 350px;
     }
     .services{
        display: flex;
        flex-wrap: wrap;
     }

    .foot1{
        padding-top: 20px;
        font-size: 22px;
    }
    .foot2-head{
        padding-left: 60px;
        margin-left: 2.5%;
    }
    .foot2-list{
        padding-left: 60px;
    }
    .icon-head{
        padding-left: 60px;
        margin-left: 2.5%;
    }
    .icons ul{
        margin-left: 2%;
      }
}

@media screen and (min-width: 1200px){

    .about-contents{
        display: flex;
    }
}
@media screen and (min-width: 990px){
    .bg-body-tertiary{
        height: 100px;
    }
}